import React from 'react'
import Typewriter from "typewriter-effect";

export default function BodyBeginner() {
    return (
            <div className="fullscreen">
                <div className="Conbody">
                    <div className="row">
                        <div className="textCentent">
                            <h1>HI, I'M ALAN KWOK</h1>
                            <h2>
                            <Typewriter
                                options={{
                                strings: ["DESIGNER", "DEVELOPER", "PROGRAMMER"],
                                autoStart: true,
                                loop: true,
                                }}
                            />
                            </h2>
                            <span className="comeForm">based in Hong Kong</span>
                            <div className="ViewWork">
                                <button>View My Works</button>
                                <div className="contactMeBTN textColor">
                                    <a href="/">Contact Me</a>
                                    <i class="bi bi-caret-down"></i>
                                </div>
                            </div>
                        </div>
                        <div className="Icon">
                            <img src={process.env.PUBLIC_URL+'/sourse/image/ICON.jpg'} alt="MashaKar Icon" />
                        </div>
                    </div>
                </div>
                <div className="downArrow">
                    <i class="bi bi-arrow-down"></i>
                </div>
            </div>
    )
}
