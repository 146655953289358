import React from 'react'

export default function AboutMe() {
    return (
        <div className='aboutMe'>
            <div className="aboutMeContainer">
                <div className="titleAbout">
                    <p>ABOUT ME</p>
                    <h2>Know Me More</h2>
                </div>
                <div className="AboutInfo">
                    <div className="AboutBody">
                        <div className="AboutName">
                            <h2>Hi, I'm <span>Kwok Wai Yung</span></h2>
                        </div>
                        <h3>I'm a designer & developer with a passion for web design. I enjoy developing simple, clean and slick websites that provide real value to the end user. Thousands of clients have procured exceptional results while working with me. Delivering work within time and budget which meets client’s requirements is our moto.</h3>
                    </div>
                    <div className="expYear">
                        <h1>3</h1>
                        <div className="expText">
                            <h2>Years of </h2>
                            <h3>Experiance</h3>
                        </div>                      
                    </div>
                </div>
                <div className="contactData">
                    <div className="smallScreenData">
                        <div className="conName smallGroup">
                            <span>Name:</span>
                            <p>Kwok Wai Yung</p>
                        </div>
                        <div className="conEmail smallGroup">
                            <span>Email:</span>
                            <p><a href= "mailto: masha181515@email.com">masha181515@gmail.com</a></p>       
                        </div>
                    </div>

                    <div className="smallScreenData">
                        <div className="conBirth smallGroup">
                            <span>Date of birth:</span>
                            <p>13 February, 1999</p>
                        </div>
                        <div className="confrom smallGroup">
                            <span>From:</span>
                            <p>Hong Kong</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
