import React from 'react'

export default function Features() {
    return (
        <div className='featuresGroup'>
            <div className="featuresContainer">
                <div className="feabody">
                    <p className='feaTitle'>FEATURES</p>
                    <h1>What I Do</h1>
                    <div className="feaGroup">

                        <div className="groupBtn">
                            <div className="groupText">
                                <div className="feaIcon">
                                    <i class="bi bi-terminal"></i>
                                </div>
                                <p><a href="/">Development</a></p>
                                <p>We’ll handle everything from to app development process until it is time to make your project live.</p>
                                <p>Skill: Front-End ,Back-End ,Database Management ,Database Management ,API Integration Design</p>
                                <div className="ArrowIcon">
                                    <i class="bi bi-arrow-return-right"></i>
                                </div>
                            </div>
                        </div>

                        <div className="groupBtn">
                            <div className="groupText">
                                <div className="feaIcon">
                                    <i class="bi bi-phone"></i>
                                </div>
                                <p><a href="/">Mobile App</a></p>
                                <p>Using our expertise in mobile application development to create beautiful pixel-perfect designs.</p>
                                <p>Language: ReactNative, Java, python</p>
                                <div className="ArrowIcon">
                                    <i class="bi bi-arrow-return-right"></i>
                                </div>
                            </div>
                        </div>

                        <div className="groupBtn">
                            <div className="groupText">
                                <div className="feaIcon">
                                    <i class="bi bi-laptop"></i>
                                </div>
                                <p><a href="/">Web Design</a></p>
                                <p>Know how to build a business website with front-end and back-end.</p>
                                <p>Language: Javascript, ReactJS, HTML, CSS, Laravel</p>
                                <div className="ArrowIcon">
                                    <i class="bi bi-arrow-return-right"></i>
                                </div>
                            </div>
                        </div>

                        <div className="groupBtn">
                            <div className="groupText">
                                <div className="feaIcon">
                                    <i class="bi bi-bar-chart"></i>
                                </div>
                                <p><a href="/">Database Administrator</a></p>
                                <p>Know how to manage databases and be able to create, redeem, editon and delete database data.</p>
                                <p>Website: MySQL, phpMyAdmin</p>
                                <div className="ArrowIcon">
                                    <i class="bi bi-arrow-return-right"></i>
                                </div>
                            </div>
                        </div>

                        <div className="groupBtn">
                            <div className="groupText">
                                <div className="feaIcon">
                                    <i class="bi bi-hdd-rack"></i>
                                </div>
                                <p><a href="/">Server Administrator</a></p>
                                <p>Ability to create and manage servers.</p>
                                <p>Website: AWS, Cpanel, WHM, Cloudflare, GoDaddy</p>
                                <div className="ArrowIcon">
                                    <i class="bi bi-arrow-return-right"></i>
                                </div>
                            </div>
                        </div>

                        <div className="groupBtn">
                            <div className="groupText">
                                <div className="feaIcon">
                                    <i class="bi bi-controller"></i>
                                </div>
                                <p><a href="/">Game Development</a></p>
                                <p>Understand different types of game development.</p>
                                <p>Engine: Unity3D</p>
                                <p>Language: C#</p>
                                <div className="ArrowIcon">
                                    <i class="bi bi-arrow-return-right"></i>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
