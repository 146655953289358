import logo from './logo.svg';
import './App.css';
import './css/beginnerCSS.css';
import './css/AboutCSS.css';
import './css/FeaturesCSS.css';
import FullScreenHeader from './components/themes/fullScreen/Header';
import BodyBeginner from './components/themes/fullScreen/BodyBeginner';
import About from './components/themes/fullScreen/AboutMe';
import Features from './components/themes/fullScreen/Features'

function App() {
  return (
    <div className="App">
      <FullScreenHeader/>
      <BodyBeginner/>
      <About/>
      <Features/>
    </div>
  );
}

export default App;
