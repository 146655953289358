import React from 'react'

export default function FullScreenHeader() {
  return (
    <header>
      <div>
        <div className="infoTitle">
          <div className="container">
            <div className="imgFrame">
              <div className="imgBox">           
                <img src={process.env.PUBLIC_URL+'/sourse/image/ICON.jpg'} alt="MashakarIcon" />
              </div>
              <p>MASHAKAR</p>
            </div>

            <div className="detailInfo textColor">
              <a href="/">HOME</a>
              <a href="/">FEATURES</a>
              <a href="/">PORTFOLIO</a>
              <a href="/">RESUME</a>
              <a href="/">TESTIMONIAL</a>
              <a href="/">CONTACTS</a>
            </div>

            <div className="detailMenu">
              <button>
                <i class="bi bi-list"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
